<template>
    <div :id="id"></div>
</template>

<script>
import Plotly from 'plotly.js-dist';

export default {
    name: 'LineGraph',
    components: { },
    props: ['x', 'y', 'xlabel', 'ylabel', 'xtype', 'ytype', 'type', 'title', 'variable', 'unit', 'id'],
    methods: {
        /**
         * Generates a plotly linegraph.
         */
        generateGraph(){
            Plotly.react(this.id, this.generateTrace(), this.generateLayout(), {responsive: true});
        },
        generateLayout(){
            return {
                title: this.title,
                xaxis: {
                    title: this.xlabel,
                    type: this.xtype ?? '-'
                },
                yaxis: {
                    title: this.ylabel,
                    type: this.ytype ?? '-'
                }
            }
        },
        generateTrace(){
            return [{
                x: this.x,
                y: this.y,
                type: this.type ?? 'scatter',
                name: `${this.variable} (${this.unit})`
            }]
        }
    },
    mounted(){
        this.generateGraph();
    },
    watch: {
        y: function(){
            this.generateGraph();
        }
    }
}
</script>

<style scoped>
#graph {
    width: 100%;
    display: block;
    box-sizing: border-box;
}
</style>