<template>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <a href="/" class="btn btn-primary mt-4">Back to Home</a>
                <div class="alert alert-danger mt-4" role="alert" v-show="invalidSensor">
                    Sensor not found.
                </div>
                <h1 class="mt-4" v-show="!invalidSensor">{{ sensor_info.name }}</h1>
            </div>
        </div>
        <div class="row justify-content-center mt-4" v-show="loading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-for="variable in Object.keys(processed_data)" v-bind:key="variable">
                <LineGraph 
                    :title="processed_data[variable].name" 
                    :id="`${sensor_info.web_id}-${variable}`"
                    :x="timestamps"
                    xlabel="Date/Time"
                    xtype="date"
                    :y="processed_data[variable].data"
                    :ylabel="`${processed_data[variable].name} (${processed_data[variable].unit})`"
                    :unit="processed_data[variable].unit"
                    :variable="variable"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../firebase/init.js';
import { query, where, collection, getDocs, orderBy, limit } from 'firebase/firestore';
import LineGraph from '@/components/LineGraph.vue';

export default {
    name: 'SensorViewer',
    components: { LineGraph },
    data() {
        return {
            sensor_info: {
                name: '',
                web_id: '',
                collection: '',
            },
            data: [],
            processed_data: {},
            timestamps: [],
            invalidSensor: false,
            loading: true,
        }
    },
    methods: {
        async getSensor(){
            const q = query(collection(db, 'sensors'), where('web_id', '==', String(this.$route.params.id)), limit(1));
            const querySnapshot = await getDocs(q);
            if(!querySnapshot.empty){
                this.sensor_info = querySnapshot.docs[0].data();
                document.title = `${this.sensor_info.name} - Sensor Readings`;
                this.fetchData();
            } else {
                this.invalidSensor = true;
                this.loading = false;
            }
        },
        async fetchData() {
            const latestData = query(collection(db, this.sensor_info.collection), orderBy(this.sensor_info.time_field, 'desc'), limit(1));
            const latestDataSnapshot = await getDocs(latestData);
            if(!latestDataSnapshot.empty){
                //query is limited to 1, so there should only be one entry
                var latestDate = new Date(latestDataSnapshot.docs[0].data()[this.sensor_info.time_field].toMillis());
                var prevDate = new Date(latestDate.setDate(latestDate.getDate() - 1));
            }

            const q = query(collection(db, this.sensor_info.collection), where(this.sensor_info.time_field, '>=', prevDate), orderBy(this.sensor_info.time_field, 'asc'));
            const querySnapshot = await getDocs(q);
            
            querySnapshot.forEach((doc) => {
                this.data.push(doc.data());
            });

            for(let i = 0; i < this.data.length; i++){
                for(let j = 0; j < this.sensor_info.variables.length; j++){

                    if(this.processed_data[this.sensor_info.variables[j].id] && Array.isArray(this.processed_data[this.sensor_info.variables[j].id].data)){
                        this.processed_data[this.sensor_info.variables[j].id].data.push(this.data[i][this.sensor_info.variables[j].id]);
                    } else {
                        this.processed_data[this.sensor_info.variables[j].id] = { data: [this.data[i][this.sensor_info.variables[j].id]] };
                        this.processed_data[this.sensor_info.variables[j].id]['name'] = this.sensor_info.variables[j].name
                        this.processed_data[this.sensor_info.variables[j].id]['unit'] = this.sensor_info.variables[j].unit
                    }
                }
                this.timestamps.push(this.data[i][this.sensor_info.time_field].toMillis());
            }
            this.loading = false;
        }
    },
    mounted() {
        this.getSensor();
    },
    watch: {
        $route: {
            immediate: true,
            handler(to){
                document.title = `Loading ${to.meta.title}`;
            }
        }
    }
}
</script>