<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center mt-4 mb-4">LoRaWAN Sensors</h1>
            </div>
        </div>
        <div class="row justify-content-center p-2">
            <div class="col-12 col-md-6 col-xl-4 mb-4" v-for="sensor in sensors" v-bind:key="sensor.web_id">
                <div class="card">
                    <div class="card-body">
                        <h2 class="h3 card-title">{{ sensor.name }}</h2>
                        <p class="card-text mb-0 small fw-bold">Last Read: {{ latest[sensor.web_id] ? new Date(latest[sensor.web_id][sensor.time_field].toMillis()).toLocaleString() : null }}</p>
                        <p class="card-text mb-0 small" v-for="variable in sensor.variables" v-bind:key="variable.id">{{ variable.name }}: {{ latest[sensor.web_id] ? `${latest[sensor.web_id][variable.id].toFixed(2)}${variable.unit}` : null }}</p>
                        <a :href="`/sensor/${sensor.web_id}`" class="btn d-block btn-primary mt-3">View Sensor</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../firebase/init.js';
import { query, collection, getDocs, orderBy, limit } from 'firebase/firestore';

export default {
    name: 'HomePage',
    data() {
        return {
            sensors: [],
            latest: {},
            doneLoading: false
        }
    },
    methods: {
        async getSensors() {
            const q = query(collection(db,'sensors'));
            const querySnapshot = await getDocs(q);
            if(!querySnapshot.empty){
                querySnapshot.forEach((doc) => {
                    this.sensors.push(doc.data());
                });
                this.sensors = this.sensors.sort((a,b) => a.name.localeCompare(b.name));
                this.fetchData();
            } else {
                //error, no sensors
            }
        },
        async fetchData() {
            for(let i = 0; i < this.sensors.length; i++){
                await this.getLatest(this.sensors[i].collection, this.sensors[i].web_id, this.sensors[i].time_field);
            }
            this.doneLoading = true;
        },
        async getLatest(sensor_collection, sensor_id, time_field){
            const q = query(collection(db, sensor_collection), orderBy(time_field, 'desc'), limit(1));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                this.latest[sensor_id] = doc.data();
            });
        }
    },
    mounted() {
        this.getSensors();
    },
    watch: {
        $route: {
            immediate: true,
            handler(to){
                document.title = to.meta.title || 'LoRaWAN Sensor Readings'
            }
        }
    }
}
</script>