import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import SensorViewer from '../views/SensorViewer.vue';

const routes = [
    { 
        path: '/',
        name: 'home',
        component: HomePage,
        meta: { title: 'LoRaWAN Sensor Readings' }
    },
    {
        path: '/sensor/:id',
        name: 'sensorviewer',
        component: SensorViewer,
        meta: { title: 'Sensor Readings' }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;